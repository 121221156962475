import { createJupiterApiClient } from '@jup-ag/api'
import { BN, web3 } from 'fbonds-core'
import { LOOKUP_TABLE } from 'fbonds-core/lib/fbond-protocol/constants'
import {
  getBurnLrtsInstructions,
  getUnstakedSolAresteaVaulBalance,
} from 'fbonds-core/lib/fbond-protocol/functions/multiply'
import { sellToRepay } from 'fbonds-core/lib/fbond-protocol/functions/perpetual'
import { SimulatedAccountInfoByPubkey } from 'solana-transactions-executor'

import { BondTradeTransaction, FraktBond } from '@banx/api/nft'
import { BONDS, WSOL_ADDRESS } from '@banx/constants'
import { parseAccountInfoByPubkey, sendTxnPlaceHolder } from '@banx/transactions'
import { getJupSwapIxns } from '@banx/transactions/jup'
import { createRepayTokenLoanTxnData } from '@banx/transactions/tokenLending'
import { caclulateBorrowTokenLoanValue } from '@banx/utils'

import { SSOL_MINT } from '.'
import { CreateSellToRepayTokenLoanTxnData } from '../common'

export const createLrtsSellToRepayTokenLoanTxnData: CreateSellToRepayTokenLoanTxnData = async (
  params,
  walletAndConnection,
) => {
  const { loan, slippageBps } = params
  const { bondTradeTransaction, fraktBond } = loan

  const instructions: web3.TransactionInstruction[] = []
  const signers: web3.Signer[] = []

  const lookupTables: web3.PublicKey[] = [new web3.PublicKey(LOOKUP_TABLE)]
  const accounts: web3.PublicKey[] = []

  const { instructions: sellToRepayIxns } = await sellToRepay({
    programId: new web3.PublicKey(BONDS.PROGRAM_PUBKEY),
    accounts: {
      userPubkey: walletAndConnection.wallet.publicKey,
      bondTradeTransaction: new web3.PublicKey(bondTradeTransaction.publicKey),
      fbond: new web3.PublicKey(fraktBond.publicKey),
      collateralTokenMint: new web3.PublicKey(loan.collateral.mint),
    },
    args: {
      amountToSell: new BN(fraktBond.fbondTokenSupply),
    },
    connection: walletAndConnection.connection,
    sendTxn: sendTxnPlaceHolder,
  })

  instructions.push(...sellToRepayIxns)

  const jupiterQuoteApi = createJupiterApiClient()

  const priceQuote = await jupiterQuoteApi.quoteGet({
    inputMint: SSOL_MINT.toBase58(),
    outputMint: WSOL_ADDRESS,
    amount: caclulateBorrowTokenLoanValue(loan).toNumber(),
    slippageBps,
    computeAutoSlippage: true,
    swapMode: 'ExactOut',
    onlyDirectRoutes: true,
    asLegacyTransaction: false,
    minimizeSlippage: false,
    dexes: ['Whirlpool'],
  })

  const unstakedSolAresteaVaulBalance = await getUnstakedSolAresteaVaulBalance(
    walletAndConnection.connection,
  )

  const burnInstructions = getBurnLrtsInstructions({
    lrtsAmount: new BN(priceQuote.inAmount),
    walletPublicKey: walletAndConnection.wallet.publicKey,
    undelegateStake: unstakedSolAresteaVaulBalance.lt(new BN(priceQuote.inAmount)),
  })

  instructions.push(...burnInstructions)

  const { instructions: jupSwapInstructions, lookupTables: jupSwapLookupTables } =
    await getJupSwapIxns({
      jupiterQuoteApi,
      quote: priceQuote,
      walletPublicKey: walletAndConnection.wallet.publicKey,
    })

  instructions.push(...jupSwapInstructions)
  lookupTables.push(...jupSwapLookupTables)

  const { instructions: repayTokenLoanIxns, accounts: repayTokenLoanAccounts } =
    await createRepayTokenLoanTxnData({ loan }, walletAndConnection)

  instructions.push(...repayTokenLoanIxns)
  accounts.push(...(repayTokenLoanAccounts ?? []))

  return {
    params,
    accounts,
    instructions,
    signers,
    lookupTables,
  }
}

export const parseSellToRepayTokenLoanSimulatedAccounts = (
  accountInfoByPubkey: SimulatedAccountInfoByPubkey,
) => {
  const results = parseAccountInfoByPubkey(accountInfoByPubkey)

  return {
    bondTradeTransaction: results?.['bondTradeTransactionV3']?.[0] as BondTradeTransaction,
    fraktBond: results?.['fraktBond']?.[0] as FraktBond,
  }
}
