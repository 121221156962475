import { LendingTokenType } from 'fbonds-core/lib/fbond-protocol/types'
import { z } from 'zod'

import { ResponseWithPagination } from '@banx/api/shared'

import {
  BorrowOfferSchemaRaw,
  CollateralTokenSchema,
  DBOfferSchema,
  OfferWithMaxMultiplierSchema,
  TokenLoanAuctionsAndListingsSchema,
  TokenLoanSchema,
  TokenMarketPreviewSchema,
  TokenMetaSchema,
  TokenOfferPreviewSchema,
  WalletTokenLoansAndOffersShema,
} from './schemas'

export type TokenMeta = z.infer<typeof TokenMetaSchema>

export type TokenLoan = z.infer<typeof TokenLoanSchema>

export type TokenMarketPreview = z.infer<typeof TokenMarketPreviewSchema>
export type TokenMarketPreviewResponse = ResponseWithPagination<TokenMarketPreview>

export type TokenOfferPreview = z.infer<typeof TokenOfferPreviewSchema>

export type WalletTokenLoansAndOffers = z.infer<typeof WalletTokenLoansAndOffersShema>

export type TokenLoanAuctionsAndListings = z.infer<typeof TokenLoanAuctionsAndListingsSchema>
export type TokenLoanAuctionsAndListingsResponse =
  ResponseWithPagination<TokenLoanAuctionsAndListings>

export type CollateralToken = z.infer<typeof CollateralTokenSchema>

export type DBOffer = z.infer<typeof DBOfferSchema>
export type BorrowOfferRaw = z.infer<typeof BorrowOfferSchemaRaw>

export enum MarketCategory {
  All = 'All',
  LST = 'LST',
  DeFi = 'DeFi',
  Meme = 'Meme',
  Governance = 'Governance',
  RWA = 'RWA',
  LP = 'LP',
  DePin = 'DePin',
  Gaming = 'Gaming',
}

interface RewardProgram {
  name: string
  rewardRate: string
  details: string
}

export interface MarketTokenRewards {
  lendingTokenType: LendingTokenType
  description: string
  rewardPrograms: Array<RewardProgram>
}

export type MarketTokenRewardsResponse = Record<string, MarketTokenRewards>

export type OfferWithMaxMultiplier = z.infer<typeof OfferWithMaxMultiplierSchema>
