import { FC } from 'react'

import classNames from 'classnames'
import { isEmpty } from 'lodash'

import { Button } from '@banx/components/Buttons'
import { DexscreenerLink } from '@banx/components/SolanaLinks'
import { StatInfo, VALUES_TYPES } from '@banx/components/StatInfo'
import { DisplayValue } from '@banx/components/TableComponents'
import Tooltip from '@banx/components/Tooltip'

import { MarketTokenRewards, core } from '@banx/api/tokens'
import { useMarketTokenRewards } from '@banx/hooks'
import { ChevronDown, Fire, StarSecondary } from '@banx/icons'
import { useTokenType } from '@banx/store/common'
import { formatTokensPerCollateralToStr, getTokenDecimals } from '@banx/utils'

import ExpandedCardContent from '../ExpandedCardContent'

import styles from './LendTokenCard.module.less'

interface LendTokenCardProps {
  market: core.TokenMarketPreview
  onClick: () => void
  isOpen: boolean
}

const LendTokenCard: FC<LendTokenCardProps> = ({ market, onClick, isOpen }) => {
  const { marketRewards } = useMarketTokenRewards(market.marketPubkey)

  return (
    <div className={styles.card}>
      <div className={classNames(styles.cardBody, { [styles.opened]: isOpen })} onClick={onClick}>
        <MarketMainInfo market={market} marketRewards={marketRewards} />
        <div className={styles.additionalContentWrapper}>
          <MarketAdditionalInfo market={market} isOpen={isOpen} />
          <Button
            type="circle"
            size="medium"
            className={classNames(styles.chevronButton, { [styles.opened]: isOpen })}
          >
            <ChevronDown />
          </Button>
        </div>
      </div>
      {isOpen && <ExpandedCardContent market={market} />}
    </div>
  )
}

export default LendTokenCard

interface MarketMainInfoProps {
  market: core.TokenMarketPreview
  marketRewards?: MarketTokenRewards
}

const MarketMainInfo: FC<MarketMainInfoProps> = ({ market, marketRewards }) => (
  <div className={styles.mainInfoContainer}>
    <img src={market.collateral.logoUrl} className={styles.collateralImage} />
    <h4 className={styles.collateralName}>{market.collateral.ticker}</h4>
    <DexscreenerLink mint={market.collateral.mint} />
    {market.isHot && (
      <Tooltip title="Market is in a huge demand and waiting for lenders!">
        <Fire />
      </Tooltip>
    )}

    {!isEmpty(marketRewards) && (
      <Tooltip title={marketRewards.description}>
        <StarSecondary />
      </Tooltip>
    )}
  </div>
)

interface MarketAdditionalInfoProps {
  market: core.TokenMarketPreview
  isOpen: boolean
}

const MarketAdditionalInfo: FC<MarketAdditionalInfoProps> = ({ market, isOpen }) => {
  const {
    collateralPrice,
    bestOffer,
    loansTvl,
    offersTvl,
    activeLoansAmount,
    activeOffersAmount,
    marketApr,
  } = market

  const { tokenType } = useTokenType()
  const decimals = getTokenDecimals(tokenType)

  const classNamesProps = {
    container: styles.additionalInfoStat,
    labelWrapper: styles.additionalInfoStatLabelWrapper,
  }

  return (
    <div className={classNames(styles.additionalInfoStats, { [styles.opened]: isOpen })}>
      <StatInfo
        label="Price"
        value={<DisplayValue value={collateralPrice / decimals} isSubscriptFormat />}
        tooltipText="Token market price"
        classNamesProps={classNamesProps}
      />
      <StatInfo
        label="Top offer"
        value={
          <DisplayValue
            value={parseFloat(formatTokensPerCollateralToStr(bestOffer, Math.log10(decimals)))}
            isSubscriptFormat
          />
        }
        tooltipText="Token market price"
        classNamesProps={classNamesProps}
      />
      <StatInfo
        label="In loans"
        value={<DisplayValue value={loansTvl} />}
        secondValue={`${activeLoansAmount} loans`}
        tooltipText="Liquidity that is locked in active loans"
        classNamesProps={classNamesProps}
      />
      <StatInfo
        label="Size"
        value={<DisplayValue value={offersTvl} />}
        secondValue={`${activeOffersAmount} offers`}
        tooltipText="Liquidity that is locked in active offers"
        classNamesProps={classNamesProps}
      />

      <StatInfo
        label="Avg APR"
        value={marketApr}
        valueType={VALUES_TYPES.PERCENT}
        tooltipText="Average interest rate across offers"
        classNamesProps={{ ...classNamesProps, value: styles.additionalAprStat }}
      />
    </div>
  )
}
