import { createJupiterApiClient } from '@jup-ag/api'
import { BN, web3 } from 'fbonds-core'
import { LOOKUP_TABLE } from 'fbonds-core/lib/fbond-protocol/constants'
import { sellToRepay } from 'fbonds-core/lib/fbond-protocol/functions/perpetual'
import {
  CreateTxnData,
  SimulatedAccountInfoByPubkey,
  WalletAndConnection,
} from 'solana-transactions-executor'

import { BondTradeTransaction, FraktBond } from '@banx/api/nft'
import { TokenLoan } from '@banx/api/tokens'
import { BONDS, USDC_ADDRESS, WSOL_ADDRESS } from '@banx/constants'
import { parseAccountInfoByPubkey, sendTxnPlaceHolder } from '@banx/transactions'
import { getJupSwapIxns } from '@banx/transactions/jup'
import { createRepayTokenLoanTxnData } from '@banx/transactions/tokenLending'
import { caclulateBorrowTokenLoanValue, isBanxSolTokenType } from '@banx/utils'

export type CreateSellToRepayTokenLoanTxnDataParams = {
  loan: TokenLoan
  slippageBps: number
}

export type CreateSellToRepayTokenLoanTxnData = (
  params: CreateSellToRepayTokenLoanTxnDataParams,
  walletAndConnection: WalletAndConnection,
) => Promise<CreateTxnData<CreateSellToRepayTokenLoanTxnDataParams>>

//TODO Jup api usage needs refactor
export const createSellToRepayTokenLoanTxnData: CreateSellToRepayTokenLoanTxnData = async (
  params,
  walletAndConnection,
) => {
  const { loan, slippageBps } = params
  const { bondTradeTransaction, fraktBond } = loan

  const { instructions: sellToRepayIxns } = await sellToRepay({
    programId: new web3.PublicKey(BONDS.PROGRAM_PUBKEY),
    accounts: {
      userPubkey: walletAndConnection.wallet.publicKey,
      bondTradeTransaction: new web3.PublicKey(bondTradeTransaction.publicKey),
      fbond: new web3.PublicKey(fraktBond.publicKey),
      collateralTokenMint: new web3.PublicKey(loan.collateral.mint),
    },
    args: {
      amountToSell: new BN(fraktBond.fbondTokenSupply),
    },
    connection: walletAndConnection.connection,
    sendTxn: sendTxnPlaceHolder,
  })

  //TODO: Refactor, move to jup utils
  const jupiterQuoteApi = createJupiterApiClient()

  const outputTokenMint = isBanxSolTokenType(bondTradeTransaction.lendingToken)
    ? WSOL_ADDRESS
    : USDC_ADDRESS

  const quote = await jupiterQuoteApi.quoteGet({
    inputMint: loan.collateral.mint,
    outputMint: outputTokenMint,
    amount: caclulateBorrowTokenLoanValue(loan).toNumber(),
    slippageBps,
    computeAutoSlippage: true,
    swapMode: 'ExactOut',
    onlyDirectRoutes: true,
    asLegacyTransaction: false,
    minimizeSlippage: false,
  })

  const { instructions: jupSwapInstructions, lookupTables: jupSwapLookupTables } =
    await getJupSwapIxns({
      jupiterQuoteApi,
      quote,
      walletPublicKey: walletAndConnection.wallet.publicKey,
    })

  const { instructions: repayTokenLoanIxns, accounts: repayTokenLoanAccounts } =
    await createRepayTokenLoanTxnData({ loan }, walletAndConnection)

  return {
    params,
    accounts: repayTokenLoanAccounts,
    instructions: [...sellToRepayIxns, ...jupSwapInstructions, ...repayTokenLoanIxns],
    lookupTables: [new web3.PublicKey(LOOKUP_TABLE), ...jupSwapLookupTables],
  }
}

export const parseSellToRepayTokenLoanSimulatedAccounts = (
  accountInfoByPubkey: SimulatedAccountInfoByPubkey,
) => {
  const results = parseAccountInfoByPubkey(accountInfoByPubkey)

  return {
    bondTradeTransaction: results?.['bondTradeTransactionV3']?.[0] as BondTradeTransaction,
    fraktBond: results?.['fraktBond']?.[0] as FraktBond,
  }
}
