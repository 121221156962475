import { FC, useMemo } from 'react'

import { useConnection, useWallet } from '@solana/wallet-adapter-react'

import EmptyList from '@banx/components/EmptyList'
import { Loader } from '@banx/components/Loader'

import { useSlippage } from '@banx/store/common'

import { useWalletTokenLoans } from '../../LoansTokenPage'
import { MultiplyPair } from '../constants'
import { useCollateralConversionRate, useCollateralYield } from '../hooks'
import { HeaderList } from './components/HeaderList'
import LeveragePositionCard from './components/LeveragePositionCard'

import styles from './LeveragePositionsContent.module.less'

type LeveragePositionsContentProps = {
  pair: MultiplyPair
}
const LeveragePositionsContent: FC<LeveragePositionsContentProps> = ({ pair }) => {
  const { connected } = useWallet()
  const { connection } = useConnection()
  const { slippageBps } = useSlippage()

  const { loans, isLoading: isLoansLoading } = useWalletTokenLoans(pair.marketTokenType)

  const { rate: conversionRate, isLoading: isConversionRateLoading } = useCollateralConversionRate({
    mint: pair.collateralMint,
    tokenType: pair.marketTokenType,
    slippageBps,
    connection,
  })

  const { collateralYield, isLoading: isCollateralYieldLoading } = useCollateralYield(pair)

  const filteredLoans = useMemo(
    () => loans.filter((loan) => loan.fraktBond.leverageBasePoints),
    [loans],
  )

  const isLoading = isLoansLoading || isConversionRateLoading || isCollateralYieldLoading

  const noLoansFound = !filteredLoans.length && !isLoading
  const showEmptyList = noLoansFound || !connected

  if (showEmptyList) return <EmptyList message="No positions found" />

  return (
    <div className={styles.content}>
      <HeaderList />

      {isLoading && <Loader />}

      {!isLoading && (
        <div className={styles.cardsList}>
          {filteredLoans.map((loan) => (
            <LeveragePositionCard
              key={loan.publicKey}
              loan={loan}
              pair={pair}
              conversionRate={conversionRate}
              collateralYield={collateralYield}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default LeveragePositionsContent
