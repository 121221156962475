import { BondOfferV3 } from 'fbonds-core/lib/fbond-protocol/types'

import { convertToDecimalString, formatTrailingZeros } from '@banx/utils'
import { ZERO_BN } from '@banx/utils/bn'

import { isOfferStateClosed } from '../offers'

export const isBondOfferV3Closed = (offer: BondOfferV3) => {
  const isStateClosed = isOfferStateClosed(offer.pairState)

  return (
    isStateClosed &&
    offer.bidCap.eq(ZERO_BN) &&
    offer.concentrationIndex.eq(ZERO_BN) &&
    offer.bidSettlement.eq(ZERO_BN) &&
    offer.fundsSolOrTokenBalance.eq(ZERO_BN)
  )
}

export const formatTokensPerCollateralToStr = (
  tokensPerCollateral: number,
  marketTokenDecimals: number,
): string => {
  const value = tokensPerCollateral / 10 ** marketTokenDecimals
  const adjustedValue = parseFloat(value.toPrecision(4))

  return formatTrailingZeros(convertToDecimalString(adjustedValue, 2))
}
