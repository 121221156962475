import { useMemo } from 'react'

import classNames from 'classnames'
import { LendingTokenType } from 'fbonds-core/lib/fbond-protocol/types'
import { orderBy } from 'lodash'
import { useNavigate } from 'react-router-dom'

import { Button } from '@banx/components/Buttons'
import { OnboardingCarousel } from '@banx/components/OnboardingCarousel'
import Table, { ColumnType } from '@banx/components/Table'
import {
  CollateralTokenCell,
  DisplayValue,
  HeaderCell,
  HorizontalCell,
  createPercentValueJSX,
} from '@banx/components/TableComponents'

import { CollateralToken, TokenMarketPreview } from '@banx/api/tokens'
import { SOL, USDC } from '@banx/icons'
import { PATHS } from '@banx/router'
import { buildUrlWithModeAndToken } from '@banx/store'
import { AssetMode, useTokenType } from '@banx/store/common'
import {
  ZERO_BN,
  bnToHuman,
  formatTokensPerCollateralToStr,
  getTokenDecimals,
  isBanxSolTokenType,
  isUsdcTokenType,
} from '@banx/utils'

import { useTokenMarketsPreview } from '../../LendToken'
import { useCollateralsList } from '../hooks'

import styles from './BorrowLendingPage.module.less'

interface TableData extends TokenMarketPreview {
  amountInWallet: number
}

const BorrowLendingPage = () => {
  const navigate = useNavigate()
  const { tokenType, setTokenType } = useTokenType()

  const { marketsPreview, isLoading: isLoadingMarketsPreview } = useTokenMarketsPreview()
  const { collateralsList, isLoading: isLoadingCollateralList } = useCollateralsList()

  const tableData = useMemo(() => {
    return getMappedAndSortedData(marketsPreview, collateralsList)
  }, [marketsPreview, collateralsList])

  const loading = isLoadingMarketsPreview || isLoadingCollateralList

  const handleGoToListingPage = () => {
    navigate(buildUrlWithModeAndToken(PATHS.BORROW_LISTING, AssetMode.Token, tokenType))
  }

  const handleGoToBorrowPage = (ticker?: string) => {
    const navigationState = ticker ? { ticker } : undefined

    navigate(buildUrlWithModeAndToken(PATHS.BORROW_NOW, AssetMode.Token, tokenType), {
      state: navigationState,
    })
  }

  const columns = getTableColumns({ handleGoToBorrowPage, tokenType })

  return (
    <div className={styles.borrowPageWrapper}>
      <div className={styles.borrowPageSection}>
        <div className={styles.introSection}>
          <div className={styles.navigationButtons}>
            <Button onClick={() => handleGoToBorrowPage()} variant="tertiary" type="standard">
              Borrow now
            </Button>

            <Button onClick={handleGoToListingPage} variant="tertiary" type="standard">
              Propose a loan
            </Button>
          </div>

          <h3>Borrow</h3>
          <p>Borrow SOL or USDC against any asset with no fixed-duration loans</p>
        </div>

        <OnboardingCarousel contentType="borrowToken" />
      </div>

      <div className={styles.selectTokenContainer}>
        <Button
          onClick={() => setTokenType(LendingTokenType.BanxSol)}
          className={classNames(styles.selectTokenButton, {
            [styles.active]: isBanxSolTokenType(tokenType),
          })}
          variant="tertiary"
          type="circle"
        >
          <SOL /> SOL
        </Button>
        <Button
          onClick={() => setTokenType(LendingTokenType.Usdc)}
          className={classNames(styles.selectTokenButton, {
            [styles.active]: isUsdcTokenType(tokenType),
          })}
          variant="tertiary"
          type="circle"
        >
          <USDC /> USDC
        </Button>
      </div>

      <Table data={tableData} columns={columns} className={styles.table} loading={loading} />
    </div>
  )
}

export default BorrowLendingPage

const getMappedAndSortedData = (
  marketsPreview: TokenMarketPreview[],
  collateralsList: CollateralToken[],
) => {
  if (!marketsPreview.length || !collateralsList.length) return []

  const mappedData = marketsPreview.map((market) => {
    const collateralMatch = collateralsList.find(
      (collateral) => collateral.marketPubkey === market.marketPubkey,
    )

    const amountInWallet = collateralMatch ? collateralMatch.amountInWallet : ZERO_BN
    const formattedAmountInWallet = bnToHuman(amountInWallet, market.collateral.decimals)

    return {
      ...market,
      amountInWallet: formattedAmountInWallet,
    }
  })

  return orderBy(
    mappedData,
    [(item) => item.amountInWallet, (item) => item.loansTvl],
    ['desc', 'desc'],
  )
}

type GetTableColumns = (props: {
  handleGoToBorrowPage: (value?: string) => void
  tokenType: LendingTokenType
}) => ColumnType<TableData>[]

const getTableColumns: GetTableColumns = ({ handleGoToBorrowPage, tokenType }) => {
  const columns: ColumnType<TableData>[] = [
    {
      key: 'collateral',
      title: <HeaderCell label="Collateral" align="left" />,
      render: (market) => {
        return (
          <CollateralTokenCell
            amount={market.amountInWallet}
            collateralPrice={market.collateralPrice}
            logoUrl={market.collateral.logoUrl}
            ticker={market.collateral.ticker}
          />
        )
      },
    },

    {
      key: 'size',
      title: <HeaderCell label="Size" tooltipText="Liquidity that is locked in active offers" />,
      render: (market) => <DisplayValue value={market.offersTvl} />,
    },
    {
      key: 'bestOffer',
      title: <HeaderCell label="Top offer" tooltipText="Highest offer among all lenders" />,
      render: (market) => {
        const marketTokenDecimals = getTokenDecimals(tokenType)
        const bestOffer = parseFloat(
          formatTokensPerCollateralToStr(market.bestOffer, Math.log10(marketTokenDecimals)),
        )
        const ltv = (bestOffer / (market.collateralPrice / marketTokenDecimals)) * 100

        return (
          <div className={styles.bestOfferCell}>
            <DisplayValue value={bestOffer} isSubscriptFormat />

            {ltv > 0 && (
              <span className={styles.bestLtvValue}>{createPercentValueJSX(ltv)} LTV</span>
            )}
          </div>
        )
      },
    },
    {
      key: 'apr',
      title: (
        <HeaderCell
          label="Avg apr"
          tooltipText="Maximum annual interest rate. Depends on the loan-to-value (LTV) offered and market capitalization"
        />
      ),
      render: (market) => (
        <HorizontalCell
          value={createPercentValueJSX(market.marketApr)}
          isHighlighted={market.marketApr > 0}
        />
      ),
    },

    {
      key: 'actionsCell',
      render: (market) => (
        <Button onClick={() => handleGoToBorrowPage(market.collateral.ticker)} size="medium">
          Collateralize
        </Button>
      ),
    },
  ]

  return columns
}
