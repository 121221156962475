import { BN } from 'fbonds-core'
import { BASE_POINTS, SECONDS_IN_YEAR } from 'fbonds-core/lib/fbond-protocol/constants'
import { calculateCurrentInterestSolPure } from 'fbonds-core/lib/fbond-protocol/functions/perpetual'
import moment from 'moment'

import { ZERO_BN, bnToNumberSafe } from '@banx/utils'

export const calculateTokenLoanBorrowAmount = (
  flashLoanCollateralAmount: BN,
  conversionRate: number,
) => {
  if (conversionRate === 0) {
    return ZERO_BN
  }

  const PRECISION_ADJUSTMENT = 1e9

  return flashLoanCollateralAmount
    .mul(new BN(PRECISION_ADJUSTMENT))
    .div(new BN(conversionRate * PRECISION_ADJUSTMENT))
}

type CalculateNetAprParams = {
  totalCollateralAmount: BN
  userEnteredCollateralAmount: BN
  conversionRate: number
  collateralYield: BN
  aprRate: number
}
export const calculateNetApr = ({
  totalCollateralAmount,
  userEnteredCollateralAmount,
  conversionRate,
  collateralYield,
  aprRate,
}: CalculateNetAprParams) => {
  if (conversionRate === 0) {
    return 0
  }

  const totalBorrowAmount = calculateTokenLoanBorrowAmount(
    totalCollateralAmount.sub(userEnteredCollateralAmount),
    conversionRate,
  )

  const debtInYear = calculateCurrentInterestSolPure({
    loanValue: bnToNumberSafe(totalBorrowAmount),
    startTime: moment().unix(),
    currentTime: moment().unix() + SECONDS_IN_YEAR,
    rateBasePoints: aprRate,
  })

  const totalCollateralInToken = totalCollateralAmount
    .mul(new BN(BASE_POINTS))
    .div(new BN(conversionRate * BASE_POINTS))

  const collateralYieldPerYearInToken = totalCollateralInToken
    .mul(collateralYield)
    .div(new BN(BASE_POINTS))

  const yieldAndDebtDiff = bnToNumberSafe(collateralYieldPerYearInToken) - debtInYear

  const userEnteredCollateralAmountInToken = userEnteredCollateralAmount
    .mul(new BN(BASE_POINTS))
    .div(new BN(conversionRate * BASE_POINTS))

  const netApr = (yieldAndDebtDiff / bnToNumberSafe(userEnteredCollateralAmountInToken)) * 100

  return netApr || 0
}
