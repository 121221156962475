import axios from 'axios'
import { BN } from 'fbonds-core'

import { ZERO_BN, convertApyToApr } from '@banx/utils'

const SOLAYER_API_URL = 'https://app.solayer.org/api'

export const fetchSolayerApr = async () => {
  const { data } = await axios.get<{ apy: number }>(`${SOLAYER_API_URL}/info`)

  const apr = convertApyToApr(data?.apy || 0, 12)

  if (!apr) return ZERO_BN

  return new BN(apr * 100)
}
