import { FC, useMemo } from 'react'

import { useConnection } from '@solana/wallet-adapter-react'
import { useQuery } from '@tanstack/react-query'
import classNames from 'classnames'
import { BN } from 'fbonds-core'
import { calcBorrowerTokenAPR } from 'fbonds-core/lib/fbond-protocol/helpers'
import { LendingTokenType } from 'fbonds-core/lib/fbond-protocol/types'
import { useNavigate } from 'react-router-dom'

import { Button } from '@banx/components/Buttons'
import { OnboardingCarousel } from '@banx/components/OnboardingCarousel'
import Tooltip from '@banx/components/Tooltip'

import { fetchOfferWithMaxMultiplier } from '@banx/api/tokens'
import { Multiply, SOL, StarSecondary, USDC } from '@banx/icons'
import { PATHS } from '@banx/router'
import { buildUrlWithModeAndToken } from '@banx/store'
import { AssetMode, useSlippage, useTokenType } from '@banx/store/common'
import { isBanxSolTokenType, isUsdcTokenType, roundByMaxDigit } from '@banx/utils'

import { JLP_PAIR, LRTS_PAIR, MultiplyPair, calculateNetApr } from '../LeveragePage'
import {
  useCollateralConversionRate,
  useCollateralYield,
  useMultiplyPair,
} from '../LeveragePage/hooks'
import { MOCK_TOKEN_ITEMS, TokenItem } from './constants'

import styles from './LeverageLanding.module.less'

export const LeverageLanding = () => {
  const navigate = useNavigate()
  const { tokenType, setTokenType } = useTokenType()

  const handleGoToLeveragePage = (ticker: string) => {
    const pathname = `${PATHS.LEVERAGE_BASE}/${ticker}`
    navigate(buildUrlWithModeAndToken(pathname, AssetMode.Token, tokenType))
  }

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.content}>
        <div className={styles.mainSection}>
          <div className={styles.mainSectionTitle}>
            <h1>Multiply</h1>
            <p>Boost your yield or leverage any token you want</p>
          </div>
          <OnboardingCarousel contentType="multiply" />
        </div>
        <div className={styles.selectTokenContainer}>
          <Button
            onClick={() => setTokenType(LendingTokenType.BanxSol)}
            className={classNames(styles.selectTokenButton, {
              [styles.active]: isBanxSolTokenType(tokenType),
            })}
            variant="tertiary"
            type="circle"
          >
            <SOL /> SOL
          </Button>
          <Button
            onClick={() => setTokenType(LendingTokenType.Usdc)}
            className={classNames(styles.selectTokenButton, {
              [styles.active]: isUsdcTokenType(tokenType),
            })}
            variant="tertiary"
            type="circle"
          >
            <USDC /> USDC
          </Button>
        </div>

        <div className={styles.tokensList}>
          <LRTSTokenRealLink
            key={LRTS_PAIR.collateralMint.toBase58()}
            pair={LRTS_PAIR}
            onClick={() => handleGoToLeveragePage(LRTS_PAIR.collateralTicker)}
          />
          <JLPTokenRealLink
            key={JLP_PAIR.collateralMint.toBase58()}
            pair={JLP_PAIR}
            onClick={() => handleGoToLeveragePage(JLP_PAIR.collateralTicker)}
          />
          {MOCK_TOKEN_ITEMS.map((tokenItem, idx) => (
            <TokenItemLink
              key={idx}
              {...tokenItem}
              onClick={() => handleGoToLeveragePage(tokenItem.ticker)}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

type TokenItemRealLinkProps = {
  pair: MultiplyPair
  onClick: () => void
  maxMultiply?: number
}

const JLPTokenRealLink: FC<TokenItemRealLinkProps> = ({ pair, onClick }) => {
  const { collateralLogoUrl, collateralTicker } = pair
  const { maxMultiplier, maxNetApr } = useMultiplyMarketInfo(pair)

  return (
    <TokenItemLink
      logoUrl={collateralLogoUrl}
      ticker={collateralTicker}
      maxApr={maxNetApr ? roundByMaxDigit(maxNetApr).toString() : undefined}
      maxMultiply={maxMultiplier}
      onClick={onClick}
    />
  )
}

const LRTSTokenRealLink: FC<TokenItemRealLinkProps> = ({ pair, onClick }) => {
  const { offerWithBestMultiplier } = useMarketOfferWithBestMultiplier(pair)
  const { collateralYield } = useCollateralYield(pair)

  const { collateralLogoUrl, collateralTicker } = pair

  const maxApr = (collateralYield.toNumber() / 100).toFixed(2)

  return (
    <TokenItemLink
      logoUrl={collateralLogoUrl}
      ticker={collateralTicker}
      extraRewardsDescription="This market earns additional rewards: Adrastea restaking"
      maxApr={maxApr}
      maxMultiply={offerWithBestMultiplier?.maxMultiplier}
      onClick={onClick}
    />
  )
}

const TokenItemLink: FC<TokenItem & { onClick: () => void }> = ({
  ticker,
  logoUrl,
  maxApr,
  maxMultiply,
  extraRewardsDescription,
  disabled,
  customFooterText,
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      className={classNames(styles.tokenItem, { [styles.tokenItemDisabled]: disabled })}
    >
      <div className={styles.tokenItemHeader}>
        {logoUrl && <img src={logoUrl} alt={ticker} />}
        <p className={styles.tokenItemTicker}>{ticker}</p>

        {!!maxApr && (
          <div className={styles.tokenItemApyWrapper}>
            <p className={styles.tokenItemApyTitle}>
              {extraRewardsDescription && (
                <Tooltip title={extraRewardsDescription}>
                  <StarSecondary />
                </Tooltip>
              )}
              Max apr
            </p>
            <p className={styles.tokenItemApyValue}>{maxApr}%</p>
          </div>
        )}
      </div>
      <div className={styles.tokenItemFooter}>
        {!!customFooterText && (
          <p className={styles.tokenItemMultiplyPlaceholder}>Go long on anything</p>
        )}

        {maxMultiply && !customFooterText && (
          <p className={styles.tokenItemMultiply}>
            <Multiply /> up to {maxMultiply}x multiply
          </p>
        )}
      </div>
    </div>
  )
}

const useMultiplyMarketInfo = (pair: MultiplyPair) => {
  const { collateralToken, isLoading: collateralTokenLoading } = useMultiplyPair(
    pair.collateralTicker,
  )
  const { slippageBps } = useSlippage()

  const { connection } = useConnection()
  const { offerWithBestMultiplier, isLoading: offerWithBestMultiplierLoading } =
    useMarketOfferWithBestMultiplier(pair)
  const { collateralYield, isLoading: collateralYieldLoading } = useCollateralYield(pair)
  const { rate: collateralConversionRate, isLoading: collateralConversionRateLoading } =
    useCollateralConversionRate({
      mint: pair.collateralMint,
      tokenType: pair.marketTokenType,
      slippageBps,
      connection,
    })

  const maxNetApr = useMemo(() => {
    if (
      !offerWithBestMultiplier ||
      !collateralYield ||
      !collateralConversionRate ||
      !collateralToken
    )
      return undefined

    const { offer, maxMultiplier } = offerWithBestMultiplier

    const marketUpfrontFee = collateralToken?.collateral.interestFee || 0
    const aprRate = calcBorrowerTokenAPR(offer.apr.toNumber(), marketUpfrontFee)

    return calculateNetApr({
      totalCollateralAmount: new BN(maxMultiplier),
      userEnteredCollateralAmount: new BN(1),
      conversionRate: collateralConversionRate,
      aprRate,
      collateralYield,
    })
  }, [collateralConversionRate, collateralToken, collateralYield, offerWithBestMultiplier])

  const isLoading =
    collateralTokenLoading ||
    offerWithBestMultiplierLoading ||
    collateralYieldLoading ||
    collateralConversionRateLoading

  return { maxNetApr, maxMultiplier: offerWithBestMultiplier?.maxMultiplier, isLoading }
}

const useMarketOfferWithBestMultiplier = (pair: MultiplyPair) => {
  const { data: offerWithBestMultiplier, isLoading } = useQuery(
    ['marketOfferWithBestMultiplier', pair.marketPublicKey, pair.marketTokenType],
    () =>
      fetchOfferWithMaxMultiplier({
        marketPubkey: pair.marketPublicKey.toBase58(),
        tokenType: pair.marketTokenType,
      }),
    {
      enabled: !!pair,
      staleTime: 5 * 60 * 1000,
      refetchOnWindowFocus: false,
    },
  )

  return { offerWithBestMultiplier, isLoading }
}
