import { BASE_POINTS } from 'fbonds-core/lib/fbond-protocol/constants'

import { TokenLoan } from '@banx/api/tokens'

/**
 * @returns user collateral amount with decimals
 */
export const calculateUserCollateralAmount = (loan: TokenLoan) => {
  const leverage = loan.fraktBond.leverageBasePoints / BASE_POINTS / 100
  const totalLoanCollateralAmount = loan.fraktBond.fbondTokenSupply

  return totalLoanCollateralAmount / leverage
}

/**
 * @returns initialCollateralRate as float number
 */
export const calculateInitialCollateralRate = (loan: TokenLoan) => {
  const userCollateralAmount = calculateUserCollateralAmount(loan)
  const boughtPartCollateral = loan.fraktBond.fbondTokenSupply - userCollateralAmount
  const initialCollateralRate =
    boughtPartCollateral / loan.bondTradeTransaction.borrowerOriginalLent

  return initialCollateralRate
}
