import { FC, useState } from 'react'

import classNames from 'classnames'
import { Navigate, useParams } from 'react-router-dom'

import { Button } from '@banx/components/Buttons'
import { Loader } from '@banx/components/Loader'
import { Tabs, useTabs } from '@banx/components/Tabs'
import TokenInput from '@banx/components/TokenInput'

import { CollateralToken } from '@banx/api/tokens'
import { Settings } from '@banx/icons'
import { PATHS } from '@banx/router'

import LeveragePositionsContent from './LeveragePositionsContent'
import { Header } from './components/Header'
import { MultiplierSlider } from './components/MultiplierSlider'
import { OrderBook } from './components/OrderBook'
import { Summary } from './components/Summary'
import { MultiplyPair } from './constants'
import { useLeverage, useMultiplyPair } from './hooks'

import styles from './LeveragePage.module.less'

export const LeveragePage = () => {
  const { ticker = '' } = useParams<{ ticker: string }>()
  const { collateralToken, pair, isLoading } = useMultiplyPair(ticker)

  const { value: currentTabValue, ...tabsProps } = useTabs({
    tabs: TABS,
    defaultValue: TabName.MULTIPLY,
  })

  if (!isLoading && (!collateralToken || !pair)) {
    return <Navigate to={PATHS.LEVERAGE_BASE} replace={true} />
  }

  return (
    <div className={styles.pageWrapper}>
      <Header title={ticker} />
      {isLoading && <Loader />}
      {pair && collateralToken && (
        <>
          <Tabs value={currentTabValue} {...tabsProps} />
          {currentTabValue === TabName.MULTIPLY && (
            <LeverageContent collateralToken={collateralToken} pair={pair} />
          )}
          {currentTabValue === TabName.POSITIONS && <LeveragePositionsContent pair={pair} />}
        </>
      )}
    </div>
  )
}

enum TabName {
  MULTIPLY = 'multiply',
  POSITIONS = 'positions',
}

const TABS = [
  {
    label: 'Multiply',
    value: TabName.MULTIPLY,
  },
  {
    label: 'Positions',
    value: TabName.POSITIONS,
  },
]

type LeverageContentProps = {
  pair: MultiplyPair
  collateralToken: CollateralToken
}
const LeverageContent: FC<LeverageContentProps> = ({ pair, collateralToken }) => {
  const {
    walletConnected,
    slippage,
    openAppSettingsModal,

    collateralAmount,
    setCollateralAmount,

    walletCollateralBalance,
    totalCollateralAmount,
    lrtsConversionRate,

    multiplierValue,
    setMultiplierValue,
    userEnteredCollateralAmount,

    simpleOffers,
    offersLoading,
    selectedOffer,
    setSelectedOffer,

    borrowBtnProps,
    onLeverageBorrow,
    borrowBtnLoading,
  } = useLeverage({ pair, collateralToken })

  const [showOrderBook, setShowOrderBook] = useState(false)

  return (
    <div className={styles.container}>
      <div className={classNames(styles.content, { [styles.contentWithOrderBook]: showOrderBook })}>
        <div className={styles.controlBtns}>
          <Button variant="tertiary" onClick={openAppSettingsModal} className={styles.slippageBtn}>
            <Settings />
            Max slippage: {slippage}%
          </Button>

          <Button variant="tertiary" onClick={() => setShowOrderBook((prev) => !prev)}>
            {showOrderBook ? 'Hide offers' : 'Show offers'}
          </Button>
        </div>
        <TokenInput
          label="Your collateral"
          value={collateralAmount}
          onChange={setCollateralAmount}
          selectedToken={
            collateralToken
              ? { collateral: collateralToken.collateral, amount: walletCollateralBalance }
              : undefined
          }
          className={styles.borrowInput}
          disabled={!walletConnected}
        />

        <MultiplierSlider
          selectedOffer={selectedOffer}
          multiplierValue={multiplierValue}
          setMultiplierValue={setMultiplierValue}
          disabled={!walletConnected}
          userEnteredCollateralAmount={userEnteredCollateralAmount}
          offers={simpleOffers}
          setSelectedOffer={setSelectedOffer}
        />

        <div className={styles.footerContent}>
          {!!selectedOffer && (
            <Summary
              selectedOffer={selectedOffer}
              conversionRate={lrtsConversionRate}
              totalCollateralAmount={totalCollateralAmount}
              userEnteredCollateralAmount={userEnteredCollateralAmount}
              pair={pair}
              collateral={collateralToken}
            />
          )}

          <Button
            onClick={onLeverageBorrow}
            className={styles.depositButton}
            disabled={borrowBtnProps.disabled}
            loading={borrowBtnLoading}
          >
            {borrowBtnProps.text}
          </Button>
        </div>
      </div>
      {showOrderBook && (
        <OrderBook
          simpleOffers={simpleOffers}
          selectedOffer={selectedOffer}
          collateral={collateralToken}
          loading={offersLoading}
        />
      )}
    </div>
  )
}
