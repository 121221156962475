import axios from 'axios'
import { BN } from 'fbonds-core'

import { BACKEND_BASE_URL } from '@banx/constants'
import { ZERO_BN } from '@banx/utils'

export const fetchJlpAprPercent = async () => {
  const { data } = await axios.get<{ data: { jlpAprPercent: number } }>(
    `${BACKEND_BASE_URL}/stats/third-party-apy`,
  )

  if (data?.data?.jlpAprPercent) {
    return new BN(data?.data?.jlpAprPercent * 100)
  }

  return ZERO_BN
}
