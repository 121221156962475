import { FC } from 'react'

import { BN } from 'fbonds-core'
import { BASE_POINTS } from 'fbonds-core/lib/fbond-protocol/constants'
import { calcBorrowerTokenAPR } from 'fbonds-core/lib/fbond-protocol/helpers'

import { StatInfo } from '@banx/components/StatInfo'
import { DisplayValue } from '@banx/components/TableComponents'

import { CollateralToken } from '@banx/api/tokens'
import { ZERO_BN, bnToFixed, bnToNumberSafe } from '@banx/utils'

import { MultiplyPair } from '../../constants'
import { calculateNetApr, calculateTokenLoanBorrowAmount } from '../../helpers'
import { useCollateralYield } from '../../hooks'
import { LeverageSimpleOffer } from '../../types'

import styles from './Summary.module.less'

type SummaryProps = {
  selectedOffer: LeverageSimpleOffer | undefined
  conversionRate: number
  totalCollateralAmount: BN
  userEnteredCollateralAmount: BN
  collateral: CollateralToken
  pair: MultiplyPair
}

export const Summary: FC<SummaryProps> = ({
  totalCollateralAmount,
  conversionRate,
  selectedOffer,
  userEnteredCollateralAmount,
  collateral,
  pair,
}) => {
  const { collateralYield } = useCollateralYield(pair)

  const borrowAmount = selectedOffer
    ? calculateTokenLoanBorrowAmount(
        totalCollateralAmount.sub(userEnteredCollateralAmount),
        conversionRate,
      )
    : ZERO_BN

  const marketUpfrontFee = collateral?.collateral.upfrontFee || 0
  const marketInterestFee = collateral?.collateral.interestFee || 0

  const upfrontFee = new BN(borrowAmount).mul(new BN(marketUpfrontFee)).div(new BN(BASE_POINTS))

  const aprRate = selectedOffer
    ? calcBorrowerTokenAPR(selectedOffer.apr.toNumber(), marketInterestFee)
    : 0

  const netApr = selectedOffer
    ? calculateNetApr({
        totalCollateralAmount,
        userEnteredCollateralAmount,
        conversionRate,
        aprRate,
        collateralYield,
      })
    : 0

  const upfrontFeePercent = marketUpfrontFee / 100

  const statClassNames = {
    value: styles.fixedStatValue,
  }

  return (
    <div className={styles.summary}>
      {/* <StatInfo
        label="Price impact"
        value={0}
        valueType={VALUES_TYPES.PERCENT}
        classNamesProps={statClassNames}
        tooltipText="Change in an asset's market price caused by executing a large trade relative to market liquidity"
        flexType="row"
      /> */}
      <StatInfo
        label="Upfront fee"
        value={
          <DisplayValue strictTokenType={pair.marketTokenType} value={bnToNumberSafe(upfrontFee)} />
        }
        tooltipText={`${upfrontFeePercent}% upfront fee charged on the loan principal amount, paid when loan is funded`}
        classNamesProps={statClassNames}
        flexType="row"
      />
      <StatInfo
        label="Borrow APR"
        value={`${(aprRate / 100).toFixed(1)}%`}
        classNamesProps={statClassNames}
        flexType="row"
      />
      <StatInfo
        label="NET APR"
        value={`${netApr.toFixed(1)}%`}
        classNamesProps={statClassNames}
        tooltipText="An annual percentage rate that combines collateral yield and loan interest rates to show the total interest cost or yield in one clear rate"
        flexType="row"
      />
      <StatInfo
        label="Total collateral"
        value={`${bnToFixed({
          value: totalCollateralAmount,
          decimals: collateral?.collateral.decimals,
          fractionDigits: 2,
        })} ${pair.collateralTicker}`}
        classNamesProps={statClassNames}
        flexType="row"
      />
      <StatInfo
        label="Total loan value"
        value={
          <DisplayValue
            strictTokenType={pair.marketTokenType}
            value={bnToNumberSafe(borrowAmount)}
          />
        }
        classNamesProps={statClassNames}
        flexType="row"
      />
    </div>
  )
}
