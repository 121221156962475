import { BN, web3 } from 'fbonds-core'
import { LendingTokenType } from 'fbonds-core/lib/fbond-protocol/types'

import { fetchSolayerApr } from '@banx/api/common'
import { fetchJlpAprPercent } from '@banx/api/tokens'
import { IS_SDK_ON_DEV_CONTRACT } from '@banx/constants'
import {
  CreateLeverageTxnData,
  CreateSellToRepayTokenLoanTxnData,
  leverage,
  lrtsSOL,
} from '@banx/transactions/leverage'
import { getTokenDecimals } from '@banx/utils'

export const MIN_MULTIPLIER_VALUE = 2

export type MultiplyPair = {
  collateralTicker: string
  collateralLogoUrl: string

  collateralMint: web3.PublicKey
  getCollateralYield: () => Promise<BN>
  marketTokenType: LendingTokenType
  marketPublicKey: web3.PublicKey
  loanValueLimit?: BN
  createLeverageTxnHandler: CreateLeverageTxnData
  createSellToRepayTxnHandler: CreateSellToRepayTokenLoanTxnData
}

export const JLP_PAIR: MultiplyPair = {
  collateralTicker: 'JLP',
  collateralLogoUrl: 'https://static.jup.ag/jlp/icon.png',
  collateralMint: new web3.PublicKey('27G8MtK7VtTcCHkpASjSDdkWWYfoqT6ggEuKidVJidD4'),
  getCollateralYield: fetchJlpAprPercent,
  marketTokenType: LendingTokenType.Usdc,
  marketPublicKey: !IS_SDK_ON_DEV_CONTRACT
    ? new web3.PublicKey('ECxo4ZF9zyTGVXq42wwnKboX4hFNmAyhyqnyCgxVAm4S')
    : new web3.PublicKey('GHMX8me6S4RGi5fs3VRpTS7VSrA5AdtwrqfM3uhKYnpr'),
  loanValueLimit: undefined,
  createLeverageTxnHandler: leverage.createLeverageTxnData,
  createSellToRepayTxnHandler: leverage.createSellToRepayTokenLoanTxnData,
} as const

export const LRTS_PAIR: MultiplyPair = {
  collateralTicker: 'lrtsSOL',
  collateralLogoUrl:
    'https://i.degencdn.com/ipfs/bafkreigoloautosntlteqccwusdjpxvtm3nm3kqvkavwxwickrk5irfety',
  collateralMint: lrtsSOL.LRTS_MINT,
  getCollateralYield: fetchSolayerApr,
  marketTokenType: LendingTokenType.BanxSol,
  marketPublicKey: !IS_SDK_ON_DEV_CONTRACT
    ? new web3.PublicKey('7EuPa26AjGdnQ7JcqM3kFhwFR4U2NQTU9guHcmaDF2G')
    : new web3.PublicKey('EJXya3FW1T8uZnxjtph7PxTeQJkb44eqYJwJHhTJhms3'),
  loanValueLimit: new BN(120 * getTokenDecimals(LendingTokenType.BanxSol)),
  createLeverageTxnHandler: lrtsSOL.createLrtsLeverageTxnData,
  createSellToRepayTxnHandler: lrtsSOL.createLrtsSellToRepayTokenLoanTxnData,
} as const

export const MULTIPLY_PAIRS = [LRTS_PAIR, JLP_PAIR] as const
