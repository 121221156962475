import { BN, web3 } from 'fbonds-core'
import { solendFlashLoanUtils } from 'fbonds-core/lib/fbond-protocol'
import { WalletAndConnection } from 'solana-transactions-executor'

import { findAssociatedTokenAddress } from '@banx/transactions'

export { SOLEND_FLASH_BORROW_FEE } from './constants'

export const getSolendFlashLoanIxns = async (params: {
  tokenMint: string
  amount: BN
  walletAndConnection: WalletAndConnection
}): Promise<{
  flashBorrow: web3.TransactionInstruction
  flashRepay: web3.TransactionInstruction
}> => {
  const { tokenMint, amount, walletAndConnection } = params
  const { wallet, connection } = walletAndConnection

  const solendMarket = await solendFlashLoanUtils.SolendMarket.initialize(connection)

  const reserve = solendMarket.reserves.find(
    ({ config }) => config.liquidityToken.mint === tokenMint,
  )
  if (reserve === null) {
    throw new Error('Solend market fetching error')
  }

  const lendingTokenAccount = findAssociatedTokenAddress(
    wallet.publicKey,
    new web3.PublicKey(tokenMint),
  )

  const flashBorrow = solendFlashLoanUtils.flashBorrowReserveLiquidityInstruction(
    amount,
    new web3.PublicKey(reserve?.config.liquidityAddress),
    lendingTokenAccount,
    new web3.PublicKey(reserve?.config.address),
    new web3.PublicKey(solendMarket.config!.address),
  )

  const flashRepay = solendFlashLoanUtils.flashRepayReserveLiquidityInstruction(
    amount,
    2,
    lendingTokenAccount,
    new web3.PublicKey(reserve?.config.liquidityAddress),
    new web3.PublicKey(reserve?.config.liquidityFeeReceiverAddress),
    lendingTokenAccount,
    new web3.PublicKey(reserve?.config.address),
    new web3.PublicKey(solendMarket.config!.address),
    wallet.publicKey,
  )

  return {
    flashBorrow,
    flashRepay,
  }
}
