import { BN } from 'fbonds-core'
import { BASE_POINTS, SECONDS_IN_YEAR } from 'fbonds-core/lib/fbond-protocol/constants'
import { calculateCurrentInterestSolPure } from 'fbonds-core/lib/fbond-protocol/functions/perpetual'
import moment from 'moment'

import { TokenLoan } from '@banx/api/tokens'
import {
  ZERO_BN,
  bnToNumberSafe,
  caclulateBorrowTokenLoanValue,
  calcTokenLoanAprWithRepayFee,
} from '@banx/utils'

export const calculateNetAprByLoan = (
  loan: TokenLoan,
  conversionRate: number,
  collateralYield: BN,
) => {
  const totalDebt = caclulateBorrowTokenLoanValue(loan)

  const aprRate = calcTokenLoanAprWithRepayFee(loan)

  const debtInYear = calculateCurrentInterestSolPure({
    loanValue: bnToNumberSafe(totalDebt),
    startTime: moment().unix(),
    currentTime: moment().unix() + SECONDS_IN_YEAR,
    rateBasePoints: aprRate,
  })

  //? Wrap if multiple tokens supported
  const collateralYieldPerYear = calcCollateralYieldPerYear(
    new BN(loan.fraktBond.fbondTokenSupply),
    collateralYield,
  )
  const collateralYieldPerYearInToken = convertYieldToTokenValue(
    collateralYieldPerYear,
    conversionRate,
  )

  const yieldAndDebtDiff = bnToNumberSafe(collateralYieldPerYearInToken) - debtInYear

  return calcNetAprFromYieldAndDebtDiff(loan, conversionRate, yieldAndDebtDiff)
}

const calcCollateralYieldPerYear = (collateralTokenAmount: BN, collateralYield: BN): BN => {
  return collateralTokenAmount.mul(collateralYield).div(new BN(BASE_POINTS))
}

const convertYieldToTokenValue = (collateralYield: BN, conversionRate: number): BN => {
  if (!conversionRate) return ZERO_BN
  return collateralYield.mul(new BN(BASE_POINTS)).div(new BN(conversionRate * BASE_POINTS))
}

const calcNetAprFromYieldAndDebtDiff = (
  loan: TokenLoan,
  conversionRate: number,
  yieldAndDebtDiff: number,
): number => {
  const leverage = loan.fraktBond.leverageBasePoints / BASE_POINTS / 100
  const userCollateralAmount = loan.fraktBond.fbondTokenSupply / leverage
  const userCollateralAmountInToken = userCollateralAmount / conversionRate
  return (yieldAndDebtDiff / userCollateralAmountInToken) * 100
}
